/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      email
      name
      phone
      country
      username
      info
      publicByName
      publicByUsername
      publicByEmail
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      TTL
      sessionUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TTL
        sessionUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      owner
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      filterRules {
        channelId
        categories
        labels
        disable
      }
      status
      media
      shared
      channelUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStream = /* GraphQL */ `
  query GetStream($id: ID!) {
    getStream(id: $id) {
      id
      owner
      streamUserId
      streamChannelId
      trial {
        createdAt
        endedAt
      }
      lastReadedTime
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listStreams = /* GraphQL */ `
  query ListStreams(
    $filter: ModelStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        streamUserId
        streamChannelId
        trial {
          createdAt
          endedAt
        }
        lastReadedTime
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      owner
      content {
        threadId
        category
        title
        comment
        color
        media
        table
      }
      deleted
      channelId
      messageUserId
      messageChannelId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const messagesByChannelId = /* GraphQL */ `
  query MessagesByChannelId(
    $messageChannelId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChannelId(
      messageChannelId: $messageChannelId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getLabel = /* GraphQL */ `
  query GetLabel($id: ID!) {
    getLabel(id: $id) {
      id
      owner
      name
      labelUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const listLabels = /* GraphQL */ `
  query ListLabels(
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        labelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      owner
      tagLabelId
      tagMessageId
      tagUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      message {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      label {
        id
        owner
        name
        labelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        tagLabelId
        tagMessageId
        tagUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        message {
          id
          owner
          deleted
          channelId
          messageUserId
          messageChannelId
          createdAt
          updatedAt
        }
        label {
          id
          owner
          name
          labelUserId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReaction = /* GraphQL */ `
  query GetReaction($id: ID!) {
    getReaction(id: $id) {
      id
      owner
      reaction
      status
      reactionMessageId
      reactionUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      message {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReactions = /* GraphQL */ `
  query ListReactions(
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        reaction
        status
        reactionMessageId
        reactionUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        message {
          id
          owner
          deleted
          channelId
          messageUserId
          messageChannelId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const reactionsByItem = /* GraphQL */ `
  query ReactionsByItem(
    $reaction: ReactionItem
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionsByItem(
      reaction: $reaction
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        reaction
        status
        reactionMessageId
        reactionUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        message {
          id
          owner
          deleted
          channelId
          messageUserId
          messageChannelId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      owner
      name
      position
      lastActiveWidgetId
      workspaceUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const listWorkspaces = /* GraphQL */ `
  query ListWorkspaces(
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        position
        lastActiveWidgetId
        workspaceUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWidget = /* GraphQL */ `
  query GetWidget($id: ID!) {
    getWidget(id: $id) {
      id
      owner
      top
      left
      width
      height
      minWidth
      minHeight
      isFullHeight
      content
      visible
      workspaceId
      widgetWorkspaceId
      widgetStreamId
      createdAt
      updatedAt
      stream {
        id
        owner
        streamUserId
        streamChannelId
        trial {
          createdAt
          endedAt
        }
        lastReadedTime
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      workspace {
        id
        owner
        name
        position
        lastActiveWidgetId
        workspaceUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listWidgets = /* GraphQL */ `
  query ListWidgets(
    $filter: ModelWidgetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWidgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        top
        left
        width
        height
        minWidth
        minHeight
        isFullHeight
        content
        visible
        workspaceId
        widgetWorkspaceId
        widgetStreamId
        createdAt
        updatedAt
        stream {
          id
          owner
          streamUserId
          streamChannelId
          lastReadedTime
          createdAt
          updatedAt
        }
        workspace {
          id
          owner
          name
          position
          lastActiveWidgetId
          workspaceUserId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getExtra = /* GraphQL */ `
  query GetExtra($id: ID!) {
    getExtra(id: $id) {
      id
      authorName
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      rating
      source
      media
      createdAt
      updatedAt
    }
  }
`;
export const listExtras = /* GraphQL */ `
  query ListExtras(
    $filter: ModelExtraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorName
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        rating
        source
        media
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfig = /* GraphQL */ `
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      id
      title
      detail
      content
      createdAt
      updatedAt
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs(
    $filter: ModelConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        detail
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      owner
      title
      detail
      content
      settingsUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        title
        detail
        content
        settingsUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      owner
      parentChannelId
      channelId
      categories
      labels
      topicUserId
      createdAt
      updatedAt
      parentChannel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        parentChannelId
        channelId
        categories
        labels
        topicUserId
        createdAt
        updatedAt
        parentChannel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPermission = /* GraphQL */ `
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      id
      module
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        module
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      description
      permissions {
        position
        permissionId
        permission
        enabled
        policy
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserRole = /* GraphQL */ `
  query GetUserRole($id: ID!) {
    getUserRole(id: $id) {
      id
      roleId
      userId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles(
    $filter: ModelUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleId
        userId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        role {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      bundle
      name
      description
      public
      item {
        itemId
        authorId
        authorName
        authorShare
      }
      features {
        position
        permissionId
        permission
        enabled
        policy
      }
      products {
        product
        bundle
        price
        discount
        packageShare
      }
      price
      trial {
        value
        unit
      }
      currency
      media
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserProduct = /* GraphQL */ `
  query GetUserProduct($id: ID!) {
    getUserProduct(id: $id) {
      id
      trial {
        createdAt
        endedAt
      }
      productId
      userId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
    }
  }
`;
export const listUserProducts = /* GraphQL */ `
  query ListUserProducts(
    $filter: ModelUserProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trial {
          createdAt
          endedAt
        }
        productId
        userId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        product {
          id
          bundle
          name
          description
          public
          price
          currency
          media
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRoleProduct = /* GraphQL */ `
  query GetRoleProduct($id: ID!) {
    getRoleProduct(id: $id) {
      id
      productId
      roleId
      createdAt
      updatedAt
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
    }
  }
`;
export const listRoleProducts = /* GraphQL */ `
  query ListRoleProducts(
    $filter: ModelRoleProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        roleId
        createdAt
        updatedAt
        role {
          id
          name
          description
          createdAt
          updatedAt
        }
        product {
          id
          bundle
          name
          description
          public
          price
          currency
          media
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      owner
      topic
      event
      payload
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        topic
        event
        payload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTradesync = /* GraphQL */ `
  query GetTradesync($id: ID!) {
    getTradesync(id: $id) {
      id
      model
      filterStr
      filterInt
      attributes
      createdAt
      updatedAt
    }
  }
`;
export const listTradesyncs = /* GraphQL */ `
  query ListTradesyncs(
    $filter: ModelTradesyncFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTradesyncs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        filterStr
        filterInt
        attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tradesyncItemsByModel = /* GraphQL */ `
  query TradesyncItemsByModel(
    $model: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTradesyncFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tradesyncItemsByModel(
      model: $model
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        model
        filterStr
        filterInt
        attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tradesyncItemsByModelFilterStr = /* GraphQL */ `
  query TradesyncItemsByModelFilterStr(
    $model: String
    $filterStrCreatedAt: ModelTradesyncItemsByModelFilterStrCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTradesyncFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tradesyncItemsByModelFilterStr(
      model: $model
      filterStrCreatedAt: $filterStrCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        model
        filterStr
        filterInt
        attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tradesyncItemsByModelFilterInt = /* GraphQL */ `
  query TradesyncItemsByModelFilterInt(
    $model: String
    $filterIntCreatedAt: ModelTradesyncItemsByModelFilterIntCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTradesyncFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tradesyncItemsByModelFilterInt(
      model: $model
      filterIntCreatedAt: $filterIntCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        model
        filterStr
        filterInt
        attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
