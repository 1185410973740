/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      email
      name
      phone
      country
      username
      info
      publicByName
      publicByUsername
      publicByEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      email
      name
      phone
      country
      username
      info
      publicByName
      publicByUsername
      publicByEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      email
      name
      phone
      country
      username
      info
      publicByName
      publicByUsername
      publicByEmail
      createdAt
      updatedAt
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      TTL
      sessionUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      TTL
      sessionUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      TTL
      sessionUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      owner
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      filterRules {
        channelId
        categories
        labels
        disable
      }
      status
      media
      shared
      channelUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      owner
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      filterRules {
        channelId
        categories
        labels
        disable
      }
      status
      media
      shared
      channelUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      owner
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      filterRules {
        channelId
        categories
        labels
        disable
      }
      status
      media
      shared
      channelUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const createStream = /* GraphQL */ `
  mutation CreateStream(
    $input: CreateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    createStream(input: $input, condition: $condition) {
      id
      owner
      streamUserId
      streamChannelId
      trial {
        createdAt
        endedAt
      }
      lastReadedTime
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateStream = /* GraphQL */ `
  mutation UpdateStream(
    $input: UpdateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    updateStream(input: $input, condition: $condition) {
      id
      owner
      streamUserId
      streamChannelId
      trial {
        createdAt
        endedAt
      }
      lastReadedTime
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteStream = /* GraphQL */ `
  mutation DeleteStream(
    $input: DeleteStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    deleteStream(input: $input, condition: $condition) {
      id
      owner
      streamUserId
      streamChannelId
      trial {
        createdAt
        endedAt
      }
      lastReadedTime
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      owner
      content {
        threadId
        category
        title
        comment
        color
        media
        table
      }
      deleted
      channelId
      messageUserId
      messageChannelId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      owner
      content {
        threadId
        category
        title
        comment
        color
        media
        table
      }
      deleted
      channelId
      messageUserId
      messageChannelId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      owner
      content {
        threadId
        category
        title
        comment
        color
        media
        table
      }
      deleted
      channelId
      messageUserId
      messageChannelId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createLabel = /* GraphQL */ `
  mutation CreateLabel(
    $input: CreateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    createLabel(input: $input, condition: $condition) {
      id
      owner
      name
      labelUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateLabel = /* GraphQL */ `
  mutation UpdateLabel(
    $input: UpdateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    updateLabel(input: $input, condition: $condition) {
      id
      owner
      name
      labelUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteLabel = /* GraphQL */ `
  mutation DeleteLabel(
    $input: DeleteLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    deleteLabel(input: $input, condition: $condition) {
      id
      owner
      name
      labelUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      owner
      tagLabelId
      tagMessageId
      tagUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      message {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      label {
        id
        owner
        name
        labelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      owner
      tagLabelId
      tagMessageId
      tagUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      message {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      label {
        id
        owner
        name
        labelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      owner
      tagLabelId
      tagMessageId
      tagUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      message {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      label {
        id
        owner
        name
        labelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createReaction = /* GraphQL */ `
  mutation CreateReaction(
    $input: CreateReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    createReaction(input: $input, condition: $condition) {
      id
      owner
      reaction
      status
      reactionMessageId
      reactionUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      message {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReaction = /* GraphQL */ `
  mutation UpdateReaction(
    $input: UpdateReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    updateReaction(input: $input, condition: $condition) {
      id
      owner
      reaction
      status
      reactionMessageId
      reactionUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      message {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReaction = /* GraphQL */ `
  mutation DeleteReaction(
    $input: DeleteReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    deleteReaction(input: $input, condition: $condition) {
      id
      owner
      reaction
      status
      reactionMessageId
      reactionUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      message {
        id
        owner
        content {
          threadId
          category
          title
          comment
          color
          media
          table
        }
        deleted
        channelId
        messageUserId
        messageChannelId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $input: CreateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    createWorkspace(input: $input, condition: $condition) {
      id
      owner
      name
      position
      lastActiveWidgetId
      workspaceUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateWorkspace = /* GraphQL */ `
  mutation UpdateWorkspace(
    $input: UpdateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    updateWorkspace(input: $input, condition: $condition) {
      id
      owner
      name
      position
      lastActiveWidgetId
      workspaceUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteWorkspace = /* GraphQL */ `
  mutation DeleteWorkspace(
    $input: DeleteWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    deleteWorkspace(input: $input, condition: $condition) {
      id
      owner
      name
      position
      lastActiveWidgetId
      workspaceUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const createWidget = /* GraphQL */ `
  mutation CreateWidget(
    $input: CreateWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    createWidget(input: $input, condition: $condition) {
      id
      owner
      top
      left
      width
      height
      minWidth
      minHeight
      isFullHeight
      content
      visible
      workspaceId
      widgetWorkspaceId
      widgetStreamId
      createdAt
      updatedAt
      stream {
        id
        owner
        streamUserId
        streamChannelId
        trial {
          createdAt
          endedAt
        }
        lastReadedTime
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      workspace {
        id
        owner
        name
        position
        lastActiveWidgetId
        workspaceUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateWidget = /* GraphQL */ `
  mutation UpdateWidget(
    $input: UpdateWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    updateWidget(input: $input, condition: $condition) {
      id
      owner
      top
      left
      width
      height
      minWidth
      minHeight
      isFullHeight
      content
      visible
      workspaceId
      widgetWorkspaceId
      widgetStreamId
      createdAt
      updatedAt
      stream {
        id
        owner
        streamUserId
        streamChannelId
        trial {
          createdAt
          endedAt
        }
        lastReadedTime
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      workspace {
        id
        owner
        name
        position
        lastActiveWidgetId
        workspaceUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteWidget = /* GraphQL */ `
  mutation DeleteWidget(
    $input: DeleteWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    deleteWidget(input: $input, condition: $condition) {
      id
      owner
      top
      left
      width
      height
      minWidth
      minHeight
      isFullHeight
      content
      visible
      workspaceId
      widgetWorkspaceId
      widgetStreamId
      createdAt
      updatedAt
      stream {
        id
        owner
        streamUserId
        streamChannelId
        trial {
          createdAt
          endedAt
        }
        lastReadedTime
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          public
          status
          media
          shared
          channelUserId
          createdAt
          updatedAt
        }
      }
      workspace {
        id
        owner
        name
        position
        lastActiveWidgetId
        workspaceUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createExtra = /* GraphQL */ `
  mutation CreateExtra(
    $input: CreateExtraInput!
    $condition: ModelExtraConditionInput
  ) {
    createExtra(input: $input, condition: $condition) {
      id
      authorName
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      rating
      source
      media
      createdAt
      updatedAt
    }
  }
`;
export const updateExtra = /* GraphQL */ `
  mutation UpdateExtra(
    $input: UpdateExtraInput!
    $condition: ModelExtraConditionInput
  ) {
    updateExtra(input: $input, condition: $condition) {
      id
      authorName
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      rating
      source
      media
      createdAt
      updatedAt
    }
  }
`;
export const deleteExtra = /* GraphQL */ `
  mutation DeleteExtra(
    $input: DeleteExtraInput!
    $condition: ModelExtraConditionInput
  ) {
    deleteExtra(input: $input, condition: $condition) {
      id
      authorName
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      rating
      source
      media
      createdAt
      updatedAt
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig(
    $input: CreateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    createConfig(input: $input, condition: $condition) {
      id
      title
      detail
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig(
    $input: UpdateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    updateConfig(input: $input, condition: $condition) {
      id
      title
      detail
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig(
    $input: DeleteConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    deleteConfig(input: $input, condition: $condition) {
      id
      title
      detail
      content
      createdAt
      updatedAt
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      owner
      title
      detail
      content
      settingsUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      owner
      title
      detail
      content
      settingsUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      owner
      title
      detail
      content
      settingsUserId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      owner
      parentChannelId
      channelId
      categories
      labels
      topicUserId
      createdAt
      updatedAt
      parentChannel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      owner
      parentChannelId
      channelId
      categories
      labels
      topicUserId
      createdAt
      updatedAt
      parentChannel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      id
      owner
      parentChannelId
      channelId
      categories
      labels
      topicUserId
      createdAt
      updatedAt
      parentChannel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        createdAt
        updatedAt
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission(
    $input: CreatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    createPermission(input: $input, condition: $condition) {
      id
      module
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission(
    $input: UpdatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    updatePermission(input: $input, condition: $condition) {
      id
      module
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission(
    $input: DeletePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    deletePermission(input: $input, condition: $condition) {
      id
      module
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      description
      permissions {
        position
        permissionId
        permission
        enabled
        policy
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      description
      permissions {
        position
        permissionId
        permission
        enabled
        policy
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      description
      permissions {
        position
        permissionId
        permission
        enabled
        policy
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserRole = /* GraphQL */ `
  mutation CreateUserRole(
    $input: CreateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    createUserRole(input: $input, condition: $condition) {
      id
      roleId
      userId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole(
    $input: UpdateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    updateUserRole(input: $input, condition: $condition) {
      id
      roleId
      userId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteUserRole = /* GraphQL */ `
  mutation DeleteUserRole(
    $input: DeleteUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    deleteUserRole(input: $input, condition: $condition) {
      id
      roleId
      userId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      bundle
      name
      description
      public
      item {
        itemId
        authorId
        authorName
        authorShare
      }
      features {
        position
        permissionId
        permission
        enabled
        policy
      }
      products {
        product
        bundle
        price
        discount
        packageShare
      }
      price
      trial {
        value
        unit
      }
      currency
      media
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      bundle
      name
      description
      public
      item {
        itemId
        authorId
        authorName
        authorShare
      }
      features {
        position
        permissionId
        permission
        enabled
        policy
      }
      products {
        product
        bundle
        price
        discount
        packageShare
      }
      price
      trial {
        value
        unit
      }
      currency
      media
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      bundle
      name
      description
      public
      item {
        itemId
        authorId
        authorName
        authorShare
      }
      features {
        position
        permissionId
        permission
        enabled
        policy
      }
      products {
        product
        bundle
        price
        discount
        packageShare
      }
      price
      trial {
        value
        unit
      }
      currency
      media
      createdAt
      updatedAt
    }
  }
`;
export const createUserProduct = /* GraphQL */ `
  mutation CreateUserProduct(
    $input: CreateUserProductInput!
    $condition: ModelUserProductConditionInput
  ) {
    createUserProduct(input: $input, condition: $condition) {
      id
      trial {
        createdAt
        endedAt
      }
      productId
      userId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateUserProduct = /* GraphQL */ `
  mutation UpdateUserProduct(
    $input: UpdateUserProductInput!
    $condition: ModelUserProductConditionInput
  ) {
    updateUserProduct(input: $input, condition: $condition) {
      id
      trial {
        createdAt
        endedAt
      }
      productId
      userId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteUserProduct = /* GraphQL */ `
  mutation DeleteUserProduct(
    $input: DeleteUserProductInput!
    $condition: ModelUserProductConditionInput
  ) {
    deleteUserProduct(input: $input, condition: $condition) {
      id
      trial {
        createdAt
        endedAt
      }
      productId
      userId
      createdAt
      updatedAt
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRoleProduct = /* GraphQL */ `
  mutation CreateRoleProduct(
    $input: CreateRoleProductInput!
    $condition: ModelRoleProductConditionInput
  ) {
    createRoleProduct(input: $input, condition: $condition) {
      id
      productId
      roleId
      createdAt
      updatedAt
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateRoleProduct = /* GraphQL */ `
  mutation UpdateRoleProduct(
    $input: UpdateRoleProductInput!
    $condition: ModelRoleProductConditionInput
  ) {
    updateRoleProduct(input: $input, condition: $condition) {
      id
      productId
      roleId
      createdAt
      updatedAt
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteRoleProduct = /* GraphQL */ `
  mutation DeleteRoleProduct(
    $input: DeleteRoleProductInput!
    $condition: ModelRoleProductConditionInput
  ) {
    deleteRoleProduct(input: $input, condition: $condition) {
      id
      productId
      roleId
      createdAt
      updatedAt
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      owner
      topic
      event
      payload
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      owner
      topic
      event
      payload
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      owner
      topic
      event
      payload
      createdAt
      updatedAt
    }
  }
`;
export const createTradesync = /* GraphQL */ `
  mutation CreateTradesync(
    $input: CreateTradesyncInput!
    $condition: ModelTradesyncConditionInput
  ) {
    createTradesync(input: $input, condition: $condition) {
      id
      model
      filterStr
      filterInt
      attributes
      createdAt
      updatedAt
    }
  }
`;
export const updateTradesync = /* GraphQL */ `
  mutation UpdateTradesync(
    $input: UpdateTradesyncInput!
    $condition: ModelTradesyncConditionInput
  ) {
    updateTradesync(input: $input, condition: $condition) {
      id
      model
      filterStr
      filterInt
      attributes
      createdAt
      updatedAt
    }
  }
`;
export const deleteTradesync = /* GraphQL */ `
  mutation DeleteTradesync(
    $input: DeleteTradesyncInput!
    $condition: ModelTradesyncConditionInput
  ) {
    deleteTradesync(input: $input, condition: $condition) {
      id
      model
      filterStr
      filterInt
      attributes
      createdAt
      updatedAt
    }
  }
`;
